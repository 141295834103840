<template>
  <b-form class="billing-form" @submit.stop.prevent="onSubmit">
    <div v-if="pageLoading"><b-spinner /></div>

    <div v-else>
      <b-form-group label="Contact" label-size="lg" label-class="font-weight-bold pt-0">
        <b-form-row>
          <b-form-group :invalid-feedback="veeErrors.first('firstName')" class="col-lg">
            <template slot="label">First Name <span class="text-danger">*</span></template>
            <b-form-input
              name="firstName"
              v-model="organization.contactFirstName"
              v-validate="{ required: true, min: 3 }"
              :state="validateState('firstName')"
              aria-describedby="firstName-feedback"
              data-vv-as="first name"
              trim
            >
            </b-form-input>
          </b-form-group>
          <b-form-group :invalid-feedback="veeErrors.first('lastName')" class="col-lg">
            <template slot="label">Last Name <span class="text-danger">*</span></template>
            <b-form-input
              name="lastName"
              v-model="organization.contactLastName"
              v-validate="{ required: true, min: 3 }"
              :state="validateState('lastName')"
              aria-describedby="lastName-feedback"
              data-vv-as="last name"
              trim
            >
            </b-form-input>
          </b-form-group>
        </b-form-row>
        <b-form-row>
          <b-form-group :invalid-feedback="veeErrors.first('phone')" class="col-lg">
            <template slot="label">Phone <span class="text-danger">*</span></template>
            <b-form-input
              name="phone"
              v-model="organization.billingPhone"
              v-validate="{ required: true, regex: /^(1-)?\d{3}-\d{3}-\d{4}$/ }"
              :state="validateState('phone')"
              aria-describedby="phone-feedback"
              v-mask="'###-###-####'"
              data-vv-as="phone"
              trim
            ></b-form-input>
          </b-form-group>
          <b-form-group :invalid-feedback="veeErrors.first('email')" class="col-lg">
            <template slot="label">Email(s) <span class="text-danger">*</span></template>
            <b-form-input
              name="email"
              v-model="organization.contactEmail"
              v-validate="{ required: true }"
              :state="emailsValid"
              aria-describedby="email-feedback"
              data-vv-as="email"
              @input="validateEmails()"
              trim
              class="mb-2.5"
            ></b-form-input>
            <div tabindex="-1" role="alert" aria-live="assertive" aria-atomic="true" class="invalid-feedback relative">
              <p class="mb-0" v-if="invalidEmails !== null">{{ emailErrorMessage }}</p>
              <ul v-if="!emailsValid">
                <li v-for="(email, index) in invalidEmails" :key="index">{{ email.email }}</li>
              </ul>
            </div>
          </b-form-group>
        </b-form-row>
      </b-form-group>
      <b-form-group label="Billing Information" label-size="lg" label-class="font-weight-bold pt-0">
        <b-form-row>
          <b-form-group :invalid-feedback="veeErrors.first('street_address')" class="col street-address">
            <template slot="label">Street Address</template>
            <b-form-input
              name="street_address"
              v-model="organization.billingAddressLine1"
              :state="validateState('street_address')"
              aria-describedby="street_address-feedback"
              data-vv-as="street address"
              trim
            ></b-form-input>
          </b-form-group>
        </b-form-row>
        <b-form-row>
          <b-form-group label="City" :invalid-feedback="veeErrors.first('city')" class="col-lg">
            <template slot="label">City</template>
            <b-form-input
              name="city"
              v-model="organization.billingCity"
              :state="validateState('city')"
              aria-describedby="city-feedback"
              data-vv-as="city"
              trim
            ></b-form-input>
          </b-form-group>
          <b-form-group
            :label="provinceStateView.dropdownLabel"
            :invalid-feedback="veeErrors.first(`${provinceStateView.dropdownLabel}_billing`)"
            class="col-lg"
          >
            <b-form-select
              :name="`${provinceStateView.dropdownLabe}l_shipping`"
              v-model="organization.billingProvince"
              :options="provinceStateView.dropdownOptions"
              :state="validateState(`${provinceStateView.dropdownLabel}_billing_input`)"
              :aria-describedby="`${provinceStateView.dropdownLabel}-billing-feedback`"
              :data-vv-as="provinceStateView.dropdownLabel"
            >
              <template #first>
                <b-form-select-option :value="null" disabled> - Please select an option - </b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
        </b-form-row>
        <b-form-row>
          <b-col cols="6">
            <PostalInput
              v-if="this.organization.country === 'CA'"
              ref="postalCode"
              label="Postal Code"
              name="postal-code-billing"
              v-model="organization.billingPostal"
              :province="organization.billingProvince"
            />
            <ZipInput
              v-if="this.organization.country === 'US'"
              ref="postalCode"
              label="ZIP Code"
              name="postal-code-billing"
              v-model="organization.billingPostal"
              :province="organization.billingProvince"
            />
          </b-col>
        </b-form-row>
      </b-form-group>

      <b-form-group label-size="lg" label-class="font-weight-bold pt-0 col custom-label">
        <template slot="label">
          <div class="custom-heading">
            <span>Shipping Information</span>
            <b-form-checkbox
              id="duplicate"
              name="duplicate"
              value="true"
              size="sm"
              @change="duplicate"
              class="duplicate"
            >
              Same as billing address
            </b-form-checkbox>
          </div>
        </template>
        <b-form-row>
          <b-form-group
            :invalid-feedback="veeErrors.first('street_address_shipping')"
            :disabled="shippingDisabled"
            class="col street-address"
          >
            <template slot="label">Street Address</template>
            <b-form-input
              name="street_address_shipping"
              v-model="organization.shippingAddressLine1"
              :state="validateState('street_address_shipping')"
              aria-describedby="street_address_shipping-feedback"
              data-vv-as="street address"
              trim
            ></b-form-input>
          </b-form-group>
        </b-form-row>
        <b-form-row>
          <b-form-group
            label="City"
            :invalid-feedback="veeErrors.first('city_shipping')"
            :disabled="shippingDisabled"
            class="col-lg"
          >
            <template slot="label">City</template>
            <b-form-input
              name="city_shipping"
              v-model="organization.shippingCity"
              :state="validateState('city_shipping')"
              aria-describedby="city_shipping-feedback"
              data-vv-as="city"
              trim
            ></b-form-input>
          </b-form-group>
          <b-form-group
            :label="provinceStateView.dropdownLabel"
            :invalid-feedback="veeErrors.first(`${provinceStateView.dropdownLabel}_shipping`)"
            :disabled="shippingDisabled"
            class="col-lg"
          >
            <b-form-select
              :name="`${provinceStateView.dropdownLabe}l_shipping`"
              v-model="organization.shippingProvince"
              :options="provinceStateView.dropdownOptions"
              :state="validateState(`{{provinceStateView.dropdownLabel}}_shipping_input`)"
              :aria-describedby="`${provinceStateView.dropdownLabel}-shipping-feedback`"
              :data-vv-as="provinceStateView.dropdownLabel"
            >
              <template #first>
                <b-form-select-option :value="null" disabled> - Please select an option - </b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
        </b-form-row>
        <b-form-row>
          <b-col cols="6" class="no-gutters">
            <PostalInput
              v-if="this.organization.country === 'CA'"
              ref="postalCode"
              label="Postal Code"
              name="postal-code-shipping"
              v-model="organization.shippingPostal"
              :province="organization.shippingProvince"
              class="col-lg"
              :disabled="shippingDisabled"
            />
            <ZipInput
              v-if="this.organization.country === 'US'"
              ref="postalCode"
              label="ZIP Code"
              name="postal-code-shipping"
              v-model="organization.shippingPostal"
              :province="organization.shippingProvince"
              :disabled="shippingDisabled"
            />
          </b-col>
        </b-form-row>
      </b-form-group>

      <div class="form-group">
        <b-alert variant="danger" :show="displayError" dismissible> {{ errorMessage }} </b-alert>
        <b-alert variant="success" :show="displaySuccess" dismissible>Successfully updated!</b-alert>
      </div>

      <BaseButton variant="success" :loading="submission" @click.native="saveBilling()">Save</BaseButton>
    </div>
  </b-form>
</template>

<script>
import { getAuth } from '@rafflebox-technologies-inc/auth-service-sdk';
import OrganizationServiceV2 from '@/lib/organization-service-v2';
import PostalInput from '@/components/inputs/PostalInput.vue';
import ZipInput from '@/components/inputs/ZipInput.vue';
import ProvinceStateService from '@/lib/province-state-view-service';
import BaseButton from '@/components/rbComponents/BaseButton.vue';

export default {
  components: {
    PostalInput,
    ZipInput,
    BaseButton
  },
  data() {
    return {
      pageLoading: false,
      submission: false,
      displayError: false,
      displaySuccess: false,
      shippingDisabled: false,
      errorMessage: null,
      provinceList: [],
      organizationId: null,
      organization: {
        contactFirstName: '',
        contactLastName: '',
        contactEmail: '',
        billingProvince: '',
        billingPostal: '',
        billingPhone: '',
        billingCity: '',
        billingAddressLine1: '',
        shippingAddressLine1: '',
        shippingCity: '',
        shippingPhone: '',
        shippingPostal: '',
        shippingProvince: '',
        country: ''
      },
      provinceStateView: {},
      emailsValid: null,
      invalidEmails: null,
      emailErrorMessage: null
    };
  },
  async created() {
    try {
      this.pageLoading = true;
      const sessionUser = await getAuth().sessionUser();
      this.organizationId = this.$route.query.id || sessionUser.organizationUuid;

      const apiOrganization = await OrganizationServiceV2.retrieveOrganization(this.organizationId);

      this.organization = { ...this.organization, ...apiOrganization };

      this.provinceStateView = ProvinceStateService.getEditBillingView(this.organization.country);
    } catch (error) {
      this.handleError(error);
    } finally {
      this.pageLoading = false;
    }
  },
  methods: {
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    validateEmails() {
      let emails = this.organization.contactEmail.split(', ');
      const regex = new RegExp(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
      const emailValidity = [];
      this.invalidEmails = null;

      // Remove any empty strings
      emails = emails.filter((email) => email !== '');

      // If there are any emails left start validating them, else fail required validation
      if (emails.length) {
        // Test and store each email, and whether or not it passes validation
        emails.forEach((email) => {
          if (email.match(regex) === null) {
            emailValidity.push({ email: email, valid: false });
          } else {
            emailValidity.push({ email: email, valid: true });
          }
        });

        // Determine if any emails are invalid
        this.invalidEmails = emailValidity.filter((email) => email.valid === false);

        // Use invalidEmails to either display an error message, or if none, display valid
        // In the error under the field it will list which entries are invalid
        if (this.invalidEmails !== null && this.invalidEmails.length >= 1) {
          this.emailsValid = false;
          this.emailErrorMessage = 'The following entries are invalid:';
        } else if (this.invalidEmails.length === 0) {
          this.emailsValid = true;
        }
      } else {
        this.emailsValid = false;
      }
    },
    async saveBilling() {
      this.displaySuccess = false;
      this.displayError = false;

      const valid = await this.$validator.validateAll();
      if (!valid) {
        return;
      }

      const body = {
        contactFirstName: this.organization.contactFirstName,
        contactLastName: this.organization.contactLastName,
        contactEmail: this.organization.contactEmail,
        billingProvince: this.organization.billingProvince,
        billingPostal: this.organization.billingPostal?.replace(' ', ''),
        billingPhone: this.organization.billingPhone,
        billingCity: this.organization.billingCity,
        billingAddressLine1: this.organization.billingAddressLine1,
        shippingAddressLine1: this.organization.shippingAddressLine1,
        shippingCity: this.organization.shippingCity,
        shippingPhone: this.organization.shippingPhone,
        shippingPostal: this.organization.shippingPostal?.replace(' ', ''),
        shippingProvince: this.organization.shippingProvince
      };

      try {
        this.submission = true;
        await OrganizationServiceV2.updateOrganization(this.organizationId, body);
        this.displaySuccess = true;
      } catch (error) {
        this.handleError(error);
      } finally {
        this.submission = false;
      }
    },
    duplicate(event) {
      if (event === 'true') {
        this.organization.shippingAddressLine1 = this.organization.billingAddressLine1;
        this.organization.shippingCity = this.organization.billingCity;
        this.organization.shippingPhone = this.organization.billingPhone;
        this.organization.shippingPostal = this.organization.billingPostal;
        this.organization.shippingProvince = this.organization.billingProvince;

        this.shippingDisabled = true;
      } else {
        this.shippingDisabled = false;
        this.resetShipping();
      }
    },
    resetShipping() {
      this.shippingDisabled = false;
      this.organization.shippingAddressLine1 = null;
      this.organization.shippingCity = null;
      this.organization.shippingPhone = null;
      this.organization.shippingPostal = null;
      this.organization.shippingProvince = null;
    },
    handleError(error) {
      this.errorMessage = error;
      this.displayError = true;
    }
  },
  watch: {
    'organization.billingAddressLine1': function (value) {
      if (this.shippingDisabled) {
        this.organization.shippingAddressLine1 = value;
      }
    },
    'organization.billingCity': function (value) {
      if (this.shippingDisabled) {
        this.organization.shippingCity = value;
      }
    },
    'organization.billingPhone': function (value) {
      if (this.shippingDisabled) {
        this.organization.shippingPhone = value;
      }
    },
    'organization.billingPostal': function (value) {
      if (this.shippingDisabled) {
        this.organization.shippingPostal = value;
      }
    },
    'organization.billingProvince': function (value) {
      if (this.shippingDisabled) {
        this.organization.shippingProvince = value;
      }
    },
    'organization.contactEmail': function (value) {
      // Ensure emails are always separated by a comma and a space
      if (this.organization.contactEmail !== null) {
        this.organization.contactEmail = value.split(/[ ,]+/).join(', ');
      }
    }
  }
};
</script>

<style>
.billing-form {
  margin-bottom: 2rem;
}

.billing-form .form-group {
  max-width: 62em !important;
  margin-bottom: 0.5rem;
}

.billing-form .form-group input,
.billing-form .form-group select,
.billing-form .form-group textarea {
  margin-bottom: 23px;
}

.billing-form > .form-group {
  margin-bottom: 1rem;
}

.billing-form .form-row label {
  position: relative;
}

.billing-form .invalid-feedback {
  position: absolute;
  bottom: 0;
}

.billing-form .custom-checkbox .custom-control-label::before {
  border-radius: 2px;
}

.billing-form .custom-label {
  padding-left: 0;
  padding-right: 0;
}

.billing-form .custom-heading {
  display: flex;
  justify-content: space-between;
}

.billing-form .custom-heading .duplicate {
  display: flex;
  align-items: flex-end;
  font-weight: 400;
}
</style>
